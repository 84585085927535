// ▶ m i x i n s  &  f u n c t i o n s

@mixin debug() {
  @if $debug {
    @content;
  }
}

// • • • • h e l p e r s

@mixin flex($grow, $shrink, $basis) {
  flex-grow: $grow;
  flex-shrink: $shrink;
  flex-basis: $basis;
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@function is-last($list, $value) {
  @if index($list, $value) == length($list) {
    @return true;
  } @else {
    @return false;
  }
}

@function to-percent($p) {
  @return $p * 100%;
}

@mixin abs-full() {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

@mixin ar($width, $height, $psuedo-el: before) {
  &:#{$psuedo-el} {
    display: block;
    content: "";
    width: 100%;
    padding-bottom: to-percent(($height/$width));
  }
}

// ◦ ◦ ◦ ◦ f e a t u r e  q u e r i e s

@mixin hover {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
}

// ◦ ◦ ◦ ◦ o r i e n t a t i o n  q u e r i e s

@mixin landscape {
  @media (orientation: landscape) {
    @content;
  }
}

@mixin portrait {
  @media (orientation: portrait) {
    @content;
  }
}

// ◦ ◦ ◦ ◦ m e d i a   q u e r i e s

@mixin mq--above($bp) {
  @media screen and (min-width: $bp) {
    @content;
  }
}

@mixin mq--below($bp) {
  @media screen and (max-width: $bp - 1px) {
    @content;
  }
}

@mixin mq--between($bp--low, $bp--high) {
  @media screen and (min-width: $bp--low) and (max-width: $bp--high - 1px) {
    @content;
  }
}

// ◦ ◦ ◦ ◦ f l e x  h e l p e r s

@mixin flex-width($width) {
  max-width: $width;
  width: $width;
}

@mixin just-ie {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}

@mixin not-ie {
  @supports not (-ms-high-contrast: none) {
    @content;
  }
}

@mixin just-ios {
  @supports (-webkit-overflow-scrolling: touch) {
    @content;
  }
}

@mixin not-ios {
  @supports not (-webkit-overflow-scrolling: touch) {
    @content;
  }
}

@function first($list) {
  @return nth($list, 1);
}

@function last($list) {
  @return nth($list, length($list));
}
