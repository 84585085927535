.page_index {
  .page-header {
    background-color: transparent;
  }
  .page-header--inner {
    background-size: cover;

    @include ar(320, 360);
    background-image: url("../assets/header_320@2x.jpg");
    @include mq--above($bp--medium) {
      @include ar(640, 400);
      background-image: url("../assets/header_640@2x.jpg");
    }
    @include mq--above($bp--large) {
      @include ar(1060, 350);
      background-image: url("../assets/header_1060@2x.jpg");
    }
    @include mq--above($bp--xlarge) {
      @include ar(2280, 700);
      background-image: url("../assets/header_1440@2x.jpg");
    }
    @include mq--above(1400px) {
      max-width: 1440px;
      margin: 0 auto;
      width: 100%;
    }
  }
  .page-section {
    &_hero {
    }
    &_top-selling {
      .captioned-image--caption-inner {
        text-align: center;
      }
    }
    &_oasis {
    }
    &_expert-tips {
      .captioned-image--caption-inner {
        text-align: center;
      }
    }
    &_video-feature {
    }
    &_tips {
      // these are stretched so that the links can all be aligned at the bottom
      .captioned-images {
        align-items: stretch;
      }
      .captioned-image--container {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
      .captioned-image--caption {
        flex: 1 1 100%;
      }
      .captioned-image--caption-inner {
        height: 100%;
      }
      .captioned-image--image {
        flex: 0 0 auto;
      }
      .tips-body {
        margin-bottom: 1rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
}
