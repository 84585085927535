// @import url("/count/361e69");

// Zilla Slab, Roboto
@import url("https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i|Zilla+Slab:400,600,700");

// PhotoWall
$font-directory: "./webfonts";
@font-face {
  font-family: "PhotoWallPosterSans";
  font-weight: 400;
  font-style: normal;
  src: url("#{$font-directory}/361E69_0_0.eot");
  src: url("#{$font-directory}/361E69_0_0.eot?#iefix")
      format("embedded-opentype"),
    url("#{$font-directory}/361E69_0_0.woff2") format("woff2"),
    url("#{$font-directory}/361E69_0_0.woff") format("woff"),
    url("#{$font-directory}/361E69_0_0.ttf") format("truetype");
}

.header {
  // background-color: transparentize($color: #000000, $amount: 0.9);
  &_xlarge {
    font-family: $ff--photowall;
    text-transform: uppercase;
    font-size: 36px;
    line-height: 1.1;
    color: $color--blue-lagoon;
    margin-bottom: 0.5rem;
    @include mq--above($bp--medium) {
      font-size: 42px;
    }
  }
  &_large {
    font-family: $ff--zilla;
    font-weight: $fw--normal;
    font-size: 1.75rem;
    line-height: 2rem;
    margin-bottom: 0.5rem;

    @include mq--above($bp--medium) {
      font-size: 2rem;
    }
    @include mq--above($bp--large) {
      font-size: 2.25rem;
      line-height: 2.5rem;
    }
  }
  &_medium {
    font-family: $ff--zilla;
    font-weight: $fw--semibold;
    font-size: 1.25rem;
    line-height: 1.875rem;
    color: #004757;
    margin-bottom: 0.5rem;
  }
  &_small {
    font-family: $ff--zilla;
    font-weight: $fw--semibold;
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: 1px;
    line-height: 1.5rem;
    margin-bottom: 0.5rem;
  }
  a {
    text-decoration: inherit;
    color: inherit;
  }
}
.body-text {
  // background-color: transparentize($color: #000000, $amount: 0.9);
}

%sg--button {
  height: 44px;
  padding: 0 16px;
  background-color: #d8f1f1;
  transition: background-color 0.3s ease;
  cursor: pointer;
  user-select: none;
  @include hover() {
    background-color: darken(#d8f1f1, 10%);
  }
}

%sg--button-text {
  font-size: 1.25rem;
  font-weight: $fw--regular;
  font-family: $ff--zilla;
  color: #00718a;
}

%sg--link {
  color: #00718a;
  text-decoration: underline;
  transition: color 0.3s ease;
}
a {
  @extend %sg--link;
  @include hover() {
    color: darken(#00718a, 10%);
  }
}
