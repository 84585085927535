.page {
}
.page-content {
  width: 100%;
  margin: 0 auto;
  // background: transparentize(blue, 0.9);
  padding: 0 $lo--sm-page-margin;

  @include mq--above($bp--medium) {
    // background: transparentize(purple, 0.9);
    padding: 0 $lo--med-page-margin;
  }
  @include mq--above($bp--large) {
    // background: transparentize(red, 0.9);
    padding: 0 $lo--lg-page-margin;
  }
  @include mq--above($bp--xlarge) {
    // background: transparentize(orange, 0.9);
    padding: 0 $lo--xl-page-margin;
  }
}

%fg--parent {
  width: calc(100% + #{$lo--sm-gutter});
  margin-left: $lo--sm-gutter * -0.5;
  margin-right: $lo--sm-gutter * -0.5;
  @include mq--above($bp--medium) {
    width: calc(100% + #{$lo--med-gutter});
    margin-left: $lo--med-gutter * -0.5;
    margin-right: $lo--med-gutter * -0.5;
  }
}
%fg--child {
  padding-left: $lo--sm-gutter * 0.5;
  padding-right: $lo--sm-gutter * 0.5;
  @include mq--above($bp--medium) {
    padding-left: $lo--med-gutter * 0.5;
    padding-right: $lo--med-gutter * 0.5;
  }
}

%full-width {
  // Pushes the container to the edges of the layout
  width: calc(100% + #{$lo--sm-page-margins});
  margin-left: -$lo--sm-page-margin;
  margin-right: -$lo--sm-page-margin;
  @include mq--above($bp--medium) {
    width: calc(100% + #{$lo--med-page-margins});
    margin-left: -$lo--med-page-margin;
    margin-right: -$lo--med-page-margin;
  }
  @include mq--above($bp--large) {
    width: calc(100% + #{$lo--lg-page-margins});
    margin-left: -$lo--lg-page-margin;
    margin-right: -$lo--lg-page-margin;
  }
  @include mq--above($bp--xlarge) {
    width: calc(100% + #{$lo--xl-page-margins});
    margin-left: -$lo--xl-page-margin;
    margin-right: -$lo--xl-page-margin;
  }
}

%fw--preserve-margins {
  padding-left: $lo--med-page-margin;
  padding-right: $lo--med-page-margin;
  @include mq--above($bp--medium) {
    padding-right: $lo--med-page-margin;
    padding-left: $lo--med-page-margin;
  }
  @include mq--above($bp--large) {
    padding-left: $lo--lg-page-margin;
    padding-right: $lo--lg-page-margin;
  }
  @include mq--above($bp--xlarge) {
    padding-left: $lo--xl-page-margin;
    padding-right: $lo--xl-page-margin;
  }
}
