/*
 * BEM Naming Conventions
 *
 * BLOCK -- ELEMENT -- MODIFIER
 * BLOCK_SLUG--ELEMENT--MODIFIER
 *
 */

// Global Variables

// Constants
// Breakpoints
$bp--small: 320px;
$bp--infra-medium: 480px;
$bp--medium: 768px;
$bp--large: 1024px;
$bp--xlarge: 1440px;
// Z-Indexes
// Colors
// -- Basic Colors
$co--white: #fff;
$co--black: #000;

$color--white: white;
$color--black: black;
// -- brand colors
$color--error: #e1301a;
$color--hover: #004757;
$color--dorado: #4a4a4a;
$color--iron: #d7d7d7;
$color--mystic: #d8f1f1;

$color--verdigris: rgba(66, 174, 168, 1);
$color--overlay-white: rgba(237, 237, 237, 0.5);
$color--chelsea-cucumber: RGBA(126, 167, 91, 1);
$color--aluminum: rgba(153, 153, 153, 1);
$color--armadillo: rgba(74, 74, 74, 1);
$color--blue-lagoon: rgba(0, 113, 138, 1);
$color--gainsboro: rgba(221, 221, 221, 1);
$color--white-smoke: rgba(246, 246, 246, 1);
$color--iceberg: rgba(216, 241, 241, 1);
$color--black-squeeze: rgba(244, 251, 251, 1);
$color--hint-of-red: rgba(249, 249, 249, 1);
$color--coral-red: rgba(242, 60, 70, 1);
$color--green-white: rgba(228, 236, 223, 1);
$color--spa-green: rgba(85, 132, 54, 1);
$color--forget-me-not: rgba(255, 239, 223, 1);
$color--peppermint: rgba(218, 234, 209, 1);
$color--prim: rgba(241, 227, 235, 1);
$color--apple: rgba(103, 171, 67, 1);

// Grid Measurements
// Gutters
// Margins
// Z-Index
// Font Weights
$fw--thin: 100;
$fw--ultralight: 200;
$fw--light: 300;
$fw--regular: 400;
$fw--normal: 400;
$fw--book: 400;
$fw--medium: 500;
$fw--semibold: 600;
$fw--bold: 700;
$fw--heavy: 700;
$fw--black: 800;

// Font Families
$ff--sans-serif: "Helvetica", sans-serif;
$ff--serif: "Georgia", serif;
$ff--zilla: "Zilla Slab", serif;
$ff--roboto: "Roboto", sans-serif;
$ff--photowall: "PhotoWallPosterSans", sans-serif;
// Easing Equations
// -- Cubic
$easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1);

// -- Circ
$easeInCirc: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
$easeInOutCirc: cubic-bezier(0.785, 0.135, 0.15, 0.86);

// -- Expo
$easeInExpo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo: cubic-bezier(1, 0, 0, 1);

// -- Quad
$easeInQuad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);

// -- Quart
$easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeInOutQuart: cubic-bezier(0.77, 0, 0.175, 1);

// -- Quint
$easeInQuint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$easeInOutQuint: cubic-bezier(0.86, 0, 0.07, 1);

// -- Sine
$easeInSine: cubic-bezier(0.47, 0, 0.745, 0.715);
$easeOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);
$easeInOutSine: cubic-bezier(0.445, 0.05, 0.55, 0.95);

// -- Back
$easeInBack: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easeInOutBack: cubic-bezier(0.68, -0.55, 0.265, 1.55);

// -- Layout Breakpoints
$bp--xsmall: 320px;
$bp--small: 480px;
$bp--medium: 640px;
$bp--large: 768px;
$bp--xlarge: 1024px;
$bp--xxlarge: 1440px;

// -- Page Margins
$lo--sm-page-margin: 20px;
$lo--sm-page-margins: $lo--sm-page-margin * 2;
$lo--med-page-margin: 22px;
$lo--med-page-margins: $lo--med-page-margin * 2;
$lo--lg-page-margin: 24px;
$lo--lg-page-margins: $lo--lg-page-margin * 2;
$lo--xl-page-margin: 28px;
$lo--xl-page-margins: $lo--xl-page-margin * 2;

// -- Grid Gutters
$lo--sm-gutter: 20px;
$lo--sm-gutters: $lo--sm-gutter * 2;
$lo--med-gutter: 32px;
$lo--med-gutters: $lo--med-gutter * 2;

// Imports
@import "utilities/reset";
@import "utilities/normalize";
@import "utilities/functions";
@import "utilities/mixins";
@import "utilities/animations";

// -- Basics
@import "base";

// -- Typography
@import "typography";

// -- Grid and Layout

// -- Components
@import "components/page";
@import "components/hero";
@import "components/form";
@import "components/image";
@import "components/carousel";
@import "components/video-feature";
@import "components/page-header";
@import "components/page-section";
@import "components/section-header";
@import "components/banner";
@import "components/captioned-images";
// -- Pages

@import "pages/index-page.scss";
