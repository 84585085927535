$gutter: 35px;

.captioned-images {
  display: flex;
}

// Horizontal Layout

.captioned-images.horizontal {
  width: 100%;
  flex-wrap: wrap;
  .captioned-image {
    @include flex-width(100%);
    // border: solid 1px black;
    &:not(:last-of-type) {
      margin-bottom: 2rem;
      @include mq--above($bp--medium) {
        margin-bottom: 5rem;
      }
    }
    @include mq--above($bp--medium) {
      &:nth-of-type(even) {
        .captioned-image--image {
          order: 2;
        }
        .captioned-image--caption {
          order: 1;
        }
      }
    }
    &--container {
      // border: solid 1px blue;
      display: flex;
      flex-wrap: wrap;
      @include mq--above($bp--medium) {
        width: calc(100% + #{$lo--med-gutter});
        margin-left: $lo--med-gutter * -0.5;
        margin-right: -$lo--med-gutter * 0.5;
      }
    }
    &--image,
    &--caption {
      @include mq--above($bp--medium) {
        padding-left: $lo--med-gutter * 0.5;
        padding-right: $lo--med-gutter * 0.5;
      }
    }
    &--caption {
      // border: solid 1px red;

      @include mq--above($bp--medium) {
        @include flex-width(to-percent(5/12));
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
    &--caption-inner {
      width: 100%; // needs this for ie11
    }

    &--image {
      @extend %full-width;
      margin-bottom: 1rem;
      @include mq--above($bp--medium) {
        @include flex-width(to-percent(7/12));
        margin: 0;
      }
    }
  }
}

// Vertical Layout

.captioned-images.vertical {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  @extend %fg--parent;

  @include mq--above($bp--medium) {
    flex-wrap: nowrap;
  }

  .captioned-image {
    @extend %fg--child;
    display: flex;
    align-items: center;

    justify-content: center;
    flex: 0 0 auto;
    @include flex-width(100%);

    @include mq--below($bp--medium) {
      &:not(:last-of-type) {
        margin-bottom: 2rem;
      }
    }
    @include mq--above($bp--medium) {
      @include flex-width(to-percent(1/3));
    }

    &--image-inner {
      text-align: center;
    }
    &--image {
      margin-bottom: 1rem;
      @include mq--below($bp--medium) {
        max-width: 320px * 0.8;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
