.carousel {
  // contains everything
}
.carousel-body {
  // contains the carousel and the prev/next
  position: relative;
  @include mq--below($bp--medium) {
    margin: 0 auto;
    width: 100%;
    max-width: calc((100vh / 2) - 40px);
  }
}
.click-shield {
  position: absolute;
  // background-color: transparentize(black, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}
.carousel-items {
  z-index: 1;
  position: relative;
  @include ar(1, 2);
  @include mq--below($bp--medium) {
    margin: 0 auto;
  }

  @include mq--above($bp--medium) {
    @include ar(2, 1);
  }
}
.carousel-item {
  position: absolute;
  top: 0;
  opacity: 0;
  &.active {
    opacity: 1;
  }

  display: flex;
  flex-wrap: wrap;
  &--inner {
    user-select: none;
    @include flex-width(to-percent(1/1));
    @include mq--above($bp--medium) {
      @include flex-width(to-percent(1/2));
    }
  }
  &.crossfade-enter {
    opacity: 0;
  }
  &.crossfade-enter-active {
    opacity: 1;
    transition: opacity 0.3s $easeInCubic;
  }
  &.crossfade-exit {
    opacity: 1;
  }
  &.crossfade-exit-active {
    opacity: 0;
    transition: opacity 0.3s $easeOutCubic;
  }
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  -webkit-tap-highlight-color: transparent;

  .pagination-dots {
    display: flex;
  }
  .pagination-dot {
    cursor: pointer;
    width: 20px;
    height: 20px;
    position: relative;
    &.active {
      &:after {
        background-color: #00718a;
      }
    }
    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: "";
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      transition: background-color 0.3s ease;
      border: solid 1px #00718a;
    }
  }
}

.prev-next-button {
  $button-width: 44px;
  &.disabled {
    cursor: default;
    &,
    &:hover,
    &:active {
      svg circle {
        stroke: #ccc;
        fill: #ccc;
        fill-opacity: 0.9;
      }
    }
  }
  -webkit-tap-highlight-color: transparent;
  transform: scale(1);
  transform-origin: center top;
  width: $button-width;
  height: $button-width;
  position: absolute;
  top: 50%;
  z-index: 99999;
  transform: translateY(-50%);
  transition: opacity 0.3s $easeOutCirc;
  cursor: pointer;

  &.next {
    right: $lo--sm-page-margin * -1;
    @include mq--above($bp--medium) {
      right: $button-width * -0.5;
    }
  }
  &.prev {
    svg {
      transform: rotate(180deg);
    }
    left: $lo--sm-page-margin * -1;
    @include mq--above($bp--medium) {
      left: $button-width * -0.5;
    }
  }
  svg {
    circle {
      fill: #00718a;
      fill-opacity: 0.9;
      transition: all 0.3s ease;
    }
    path {
      fill: white;
    }
  }
  @include hover() {
    svg circle {
      fill-opacity: 1;
    }
  }
  &:active {
    svg circle {
      fill: darken(#00718a, 10%);
    }
  }
}
