.form {
  label {
    display: none;
  }

  &_find-store {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .input-wrapper,
    .button-container {
      width: 100%;
    }

    @include mq--below($bp--medium) {
      .input-wrapper {
        margin-bottom: 12px;
      }
    }
    @include mq--above($bp--medium) {
      flex-wrap: nowrap;
      .input-wrapper {
        flex: 1 1 auto;
        @include flex-width(100%);
        max-width: 270px;
        margin-right: 10px;
      }
      .button-container {
        flex: 0 0 auto;
        @include flex-width(auto);
      }
    }
  }

  input {
    border: 0;
    font-size: 1rem;
    height: 44px;
    width: 100%;
    border: solid 1px transparent;
    -webkit-appearance: none;
    border-radius: 0px;

    &:focus {
      outline: none;
      border-color: #979797;
    }
    &:active {
      border-color: #979797;
    }
    background-position: 14px center;
    background-size: 14px 16px;
    padding: 0 1rem;
    padding-left: 42px;
  }
}

.button {
  width: 100%;
  @extend %sg--button;
  @extend %sg--button-text;
}
