.video-feature {
  width: 100%;
  background-color: transparentize(black, 0.5);
  @include ar(16, 9);
  background-size: cover;
  position: relative;
  .play-button {
    position: absolute;
    width: 48px;
    height: 48px;
    @include mq--below($bp--medium) {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    @include mq--above($bp--medium) {
      right: 30px;
      bottom: 20px;
    }
    path {
      fill: white;
    }
  }
}
