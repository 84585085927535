html {
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-weight: inherit;
  font-family: inherit;
  font-size: inherit;
  text-align: inherit;
}

body {
  font-size: 1rem;
  line-height: 1.5;
  font-family: $ff--roboto;
  color: $color--armadillo;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}
