.banner {
  @extend %full-width;
  @extend %fw--preserve-margins;
  background-color: orange;
  text-align: center;

  display: flex;
  justify-content: center;

  &--container {
    // margin: 0 auto;
    width: 100%;
    max-width: 950px;
  }
}

.banner_find-store {
  padding-top: $lo--sm-gutter;
  padding-bottom: $lo--sm-gutter;
  @include mq--above($bp--medium) {
    padding-top: $lo--med-gutter;
    padding-bottom: $lo--med-gutter;
  }

  .find-store-banner--body {
    .header,
    .body-text {
      color: white;
    }
    .header {
      // margin-bottom: 0.75rem;
    }

    margin-bottom: 1rem;
  }
}

.banner_text {
  padding-top: $lo--sm-gutter;
  padding-bottom: $lo--sm-gutter;
  @include mq--above($bp--medium) {
    padding-top: $lo--med-gutter * 0.5;
    padding-bottom: $lo--med-gutter * 0.5;
  }
  .banner--container {
    @extend %fg--parent;
    display: flex;
    align-items: center;

    .text-banner--image,
    .text-banner--body {
      @extend %fg--child;
    }
    .text-banner--body {
    }
    .text-banner--image {
      flex: 0 0 auto;
    }
    @include mq--below($bp--medium) {
      flex-wrap: wrap;
      justify-content: space-between;
      .text-banner--image {
        &.image_left {
          order: 1;
          @include flex-width(to-percent(1/2));
        }
        &.image_right {
          order: 2;
          @include flex-width(to-percent(1/2));
        }
        &.image_left,
        &.image_right {
          .image {
            margin: 0 auto;
            max-width: 150px;
          }
        }
      }
      .text-banner--body {
        order: 3;
        @include flex-width(to-percent(1/1));
      }
    }
    @include mq--above($bp--medium) {
      .text-banner--image {
        bottom: -15%;
        position: relative;
        @include ar(1, 1);
        width: 160px;
        .image {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
        }
      }
    }
  }
}
