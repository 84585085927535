.page-section {
  // border: solid 1px red;
  padding-top: $lo--sm-gutter;
  padding-bottom: $lo--sm-gutter;
  @include mq--above($bp--medium) {
    padding-top: $lo--med-gutter * 2;
    padding-bottom: $lo--med-gutter * 2;
    margin: 0 auto;
    max-width: 810px;
  }
  & + & {
    border-top: solid 1px #979797;
  }
}
